import React from 'react'

function Footer() {

  return (
    <div id="footerDiv">
      <h3>CODED BY AN OPERA SINGER</h3>
    </div>
  )
}


export default Footer