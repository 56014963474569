import React from 'react'
const headshot = require('../../src/images/adrianRosalesHeadshot19.jpg')

class About extends React.Component {
  constructor() {
    super()
    this.state={}
  }

  render() {
    return (
      <div id="aboutLand">
        <h1 className="sectionHead" id="aboutLandHead">About Adrian</h1>
        <div id="aboutLandInfoDiv">
        <img id="aboutHeadshot" src={headshot} alt="adrianRosales"/>

<p>
I grew up in Portland, OR and earned my Bachelors and Masters of Music degrees in Operatic Performance at the University of Southern California. In 2017, I indulged my curiosity for programming by enrolling in the Thinkful Full-Stack Web Development Bootcamp.

Since completing the bootcamp, I have worked with several startups on projects for large companies and organizations like Adidas, CDK Global, Papa Murphy's, the Boston Children's Hospital, and many others.

Taking the leap from my artistic background into a technical field has been challenging, fun, rewarding, and has introduced me to a variety of different people who I have had immense pleasure learning from and working with. I very much look forward to continuing my journey in this field and utilizing the knowledge and skills I have cultivated on new challenges and projects!
<br/><br/>
Since the pandemic started, I have experienced some deprecation of APIs and services fueling this portfolio site and most of my projects. I will be creating a new portfolio site soon and will rebuild my projects from the ground up using best practices I have acquired from my most recent professional experiences.
</p>
        </div>
        
      </div>
    )
  }
}

export default About