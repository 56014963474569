import React from 'react'
import axios from 'axios'
import { SpinnerRoundFilled } from 'spinners-react'
import mushroom from '../images/mario-mushroom-transparent.png';
import robotPainter from '../images/robot-painting.jpg';

import { Card, Collapse, Modal, Button } from 'antd'
import { base64ToImage } from '../helperMethods/base64ToImage'
import { makeTechSection } from '../helperMethods/makeTechSection'
var _ = require('lodash')
const { Panel } = Collapse
const { Meta } = Card

class Projects extends React.Component {
  constructor() {
    super()

    this.state = {
      data: [],
      techGroups: [],
      techModalVisible: false
    }
    this.myRef = React.createRef()
    this.backendURL = "http://localhost:4007"
    this.modalStuff = ''
  }

  UNSAFE_componentWillMount = () => {
    if (process.env.NODE_ENV === "production") {
      this.backendURL = "https://sleepy-hollows-70516.herokuapp.com"
    }
    this.retrieveTechnologies()
  }

  componentDidMount = () => {
    this.retrieveProjects()
  }

  retrieveTechnologies = () => {
    return axios.get(`${this.backendURL}/tech`)
      .then(response => {
        this.setState({ techData: response.data })
      })
      .catch(err => {
        console.error(err)
      })
  }

  retrieveProjects = () => {
    return axios.get(`${this.backendURL}/projects`)
      .then(response => {
        this.setState({ data: response.data })
      })
      .catch(err => {
        console.error(err)
      })
  }

  establishTechIcons = object => {
    return (
      <div>
        Frameworks
        <div>
          {Object.values(object)[0] || ''}
        </div>
        Languages
        <div>
          {Object.values(object)[1] || ''}
        </div>
        Testing
        <div>

        </div>
      </div>
    )
  }

  getTechGroups = (item) => {
    let stateTech = this.state.techData || []
    let technologiesUsed = []

    item.technologies.forEach(number => {
      stateTech.forEach(item => {
        if (number == item.tech_id) {
          technologiesUsed.push(item)
        }
      })
    })

    technologiesUsed = technologiesUsed.sort((a, b) => (a.technology_type_id > b.technology_type_id) ? 1 : -1)
    let techGroups = _.groupBy(technologiesUsed, 'technology_type_id'.toString())
    return techGroups
  }

  collapseCB = (key) => {
    console.log(key)
  }

  render() {
    let projects = (this.state.data).map((item, index) => {
      let techGroups = this.getTechGroups(item)
      let languages = techGroups[1] || []
      let frontEndFWS = techGroups[2]
      let backEndFWS = techGroups[3]
      let libraries = techGroups[4]
      let testingLibraries = techGroups[5]
      let uiFWS = techGroups[6]
      let cms = techGroups[7]
      let database = techGroups[8]
      let ci = techGroups[9]
      const imageSrc = base64ToImage(item)

      return (
        <div className="projectEntry" key={index}>
          <Card
            hoverable
            style={index % 2 == 0 ? { background: '' } : { background: 'cyan' }}
          >
            <a href={item.projectlink} target="_blank">
              <div className="projectLinkDiv">
                <Meta title={item.name} />
                <img className="projectIcon" src={imageSrc} />
              </div>
            </a>
            <Collapse defaultActiveKey={['1']} onChange={this.collapseCB}>
              <Panel showArrow={false} className="panelTitle" header="Description" key="1" disabled>
                <p className="projectDescriptionP">{item.summary}</p>
              </Panel>
              <Panel className="panelTitle" header="Links" key="2">
                <ul className="projectLinksUL">
                  <li><a href={item.projectlink} target="_blank">Project Link</a></li>
                  <li><a href={item.repolink} target="_blank">Repo Link</a></li>
                </ul>

              </Panel>
              <Panel visible="false" className="panelTitle techPanel" header="Tech" key="3">
                {makeTechSection("Languages", languages)}
                {makeTechSection("Front-End Frameworks", frontEndFWS)}
                {makeTechSection("Back-End Frameworks", backEndFWS)}
                {makeTechSection("Libraries", libraries)}
                {makeTechSection("Testing Libraries", testingLibraries)}
                {makeTechSection("UI Frameworks", uiFWS)}
                {makeTechSection("Content Management Systems", cms)}
                {makeTechSection("Databases", database)}
                {makeTechSection("Continuous Integrations", ci)}
              </Panel>
            </Collapse>
          </Card>
        </div>
      )
    })

    return (
      <div id="projectLand">
        <h1 className="sectionHead" id="projectsSectionHead">
          Projects
        </h1>
        <Modal
          visible={this.state.techModalVisible}
          title="Tech"
          onCancel={() => this.closeTechModal()}
          footer={null}
        >
          {this.modalStuff}
        </Modal>
        <div
          id="projectBox"
        >
          {
            projects.length == 0 ?
              // <SpinnerRoundFilled />
              <>
              <div className="projectEntry">
                <Card
                  hoverable
                  style={{ background: 'cyan' }}
                >
                  <a href="https://rad-dieffenbachia-a55103.netlify.app/" target="_blank">
                    <div className="projectLinkDiv">
                      <Meta title="Crosspad" />
                      <img className="projectIcon" src={mushroom} />
                    </div>
                  </a>
                  <Collapse defaultActiveKey={['1']} onChange={this.collapseCB}>
                    <Panel showArrow={false} className="panelTitle" header="Description" key="1" disabled>
                      <p className="projectDescriptionP">Track the publics' approval of video-games and their related entries over time! This app was built with React, React-Context, and Express. New version coming soon in 5/22!</p>
                    </Panel>
                    <Panel className="panelTitle" header="Links" key="2">
                      <ul className="projectLinksUL">
                        <li><a href="https://rad-dieffenbachia-a55103.netlify.app/" target="_blank">Project Link</a></li>
                        <li><a href="https://github.com/lightsage88/crosspad" target="_blank">Repo Link</a></li>
                      </ul>
                    </Panel>
                  </Collapse>
                </Card>
              </div>
              

              <div className="projectEntry">
                <Card
                  hoverable
                  style={{ background: 'cyan' }}
                >
                  <a href="https://ai-memory.onrender.com" target="_blank">
                    <div className="projectLinkDiv">
                      <Meta title="AI Memory" />
                      <img className="projectIcon" src={robotPainter} />
                    </div>
                  </a>
                  <Collapse defaultActiveKey={['1']} onChange={this.collapseCB}>
                    <Panel showArrow={false} className="panelTitle" header="Description" key="1" disabled>
                      <p className="projectDescriptionP">Play a game of memory with cards featuring AI-Generated Art from prompts you enter, then match up your prompts to the cards you helped create! </p>
                      <strong>Note: This is a new project, so the styling is coming soon!</strong>
                    </Panel>
                    <Panel className="panelTitle" header="Links" key="2">
                      <ul className="projectLinksUL">
                        <li><a href="https://ai-memory.onrender.com" target="_blank">Project Link</a></li>
                        <li><a href="https://github.com/lightsage88/ai-memory" target="_blank">Repo Link</a></li>
                        <li><a href="https://github.com/lightsage88/ai-memory-api" target="_blank">API Repo Link</a></li>
                      </ul>
                    </Panel>
                  </Collapse>
                </Card>
              </div>
              </> :
              projects
          }
        </div>
      </div>
    )
  }
}

export default Projects